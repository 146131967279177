import * as bootstrap from 'bootstrap';
import Helper from './helper';

import.meta.glob([
    '../img/**'
]);

class App {
    constructor() {
        this.init();
    }

    init() {
        this.cookies();
        this.scrollTo();
        this.responsiveEmbeds();
    }

    /**
     * Set cookie preferences
     */
    cookies() {
        let form = document.getElementById('form-cookies');

        if (form === null) {
            return false;
        }

        // Show the cookie form
        form.classList.remove('fade');

        // Add cookie selection state
        document.body.classList.add('cookie-selection');

        let options = form.querySelector('.cookie-options');

        let textChecked   = 'Alle cookies accepteren';
        let textUnchecked = 'Geselecteerde cookies accepteren'

        let buttonSubmit = form.querySelector('button[type="submit"]');

        let isAllCookiesChecked = function () {
            let checked = true;

            options.querySelectorAll('.form-check-input').forEach(switchElement => {
                if (switchElement.checked === false) {
                    checked = false;
                }
            });

            return checked;
        }

        options.querySelectorAll('.form-check-input').forEach(input => {
            // Bind the change event listener to the form check input
            input.addEventListener('change', function () {
                buttonSubmit.innerText = isAllCookiesChecked() ? textChecked : textUnchecked;
            });

            // Manually create on on-change event
            let event = new Event('change');

            // Manually dispatch event
            input.dispatchEvent(event);
        });

        form.addEventListener('submit', function(e) {
            e.preventDefault();

            Helper.post(this.action, new FormData(this)).then(() => {
                // Remove cookie selection state
                document.body.classList.remove('cookie-selection');

                // Fade out the form
                form.classList.add('fade');

                setTimeout(function () {
                    // Reload page
                    location.reload();
                }, 1000);
            })
            .catch(error => {
                // Reject the promise
                return Promise.reject(error.statusText);
            });
        });
    }

    /**
     * Smooth scroll to target element
     */
    scrollTo() {
        document.querySelectorAll('a[href^="#content"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                const targetId = this.getAttribute('href').slice(1); // Get the part after '#'

                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                    window.scrollTo({
                        top: targetElement.offsetTop,
                        behavior: 'smooth' // Enables smooth scrolling
                    });
                }
            });
        });
    }

    /**
     * Wrap iframes in a responsive container
     */
    responsiveEmbeds() {
        let wrap = function (toWrap, wrapper) {
            wrapper = wrapper || document.createElement('div');
            toWrap.parentNode.insertBefore(wrapper, toWrap);
            return wrapper.appendChild(toWrap);
        }

        // Responsive embeds
        document.querySelectorAll('iframe:not(.podio-webform-frame)').forEach(iframe => {
            let wrapper = document.createElement('div');

            wrapper.setAttribute('class', 'ratio ratio-16x9');

            wrap(iframe, wrapper);
        });
    }
}

window.addEventListener('load', () => new App());
