export default class Helper {
    constructor() {

    }

    static roundNumber(number, places) {
        return + (Math.round(number + 'e+' + places) + 'e-' + places);
    }

    /**
     * Observe event
     *
     * @param element
     * @param event
     */
    static observeEvent(element, event) {
        const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

        const observer = new MutationObserver((mutations) => {
            if (mutations[0].attributeName === 'value') {
                // Manually create an on-change event
                const e = new Event(event);

                // Manually dispatch event
                element.dispatchEvent(e);
            }
        });

        observer.observe(element, {
            attributes: true
        });
    }

    /**
     * Bind an event listener to elements within a certain scope
     *
     * @param parent
     * @param event
     * @param selector
     * @param handler
     */
    static bindEventListener(parent, event, selector, handler) {
        parent.addEventListener(event, function (e) {
            if (e.target.matches(selector + ', ' + selector + ' *')) {
                handler.apply(e.target.closest(selector), arguments);
            }
        }, false);
    }

    /**
     * Delay event listeners
     *
     * @param func
     * @param timeout
     * @returns {(function(): void)|*}
     */
    static debounce(func, timeout = 200) {
        let timeout_id;

        return function () {
            const scope = this, args = arguments;

            clearTimeout(timeout_id);

            timeout_id = setTimeout(function () {
                func.apply(scope, Array.prototype.slice.call(args));
            }, timeout);
        };
    }

    /**
     * Generate a random string
     *
     * @param length
     * @returns {string}
     */
    static generateString(length) {
        return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
    }

    /**
     * Copy the text to the clipboard
     *
     * @param text
     */
    static copyToClipboard(text) {
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        dummy.blur();
        document.body.removeChild(dummy);
    }

    /**
     * Serialize object
     *
     * @param params
     * @param prefix
     * @returns {string}
     */
    static serializeQuery(params, prefix = null) {
        const query = Object.keys(params).map((key) => {
            const value = params[key];

            if (params.constructor === Array) {
                key = `${prefix}[]`;
            }
            else if (params.constructor === Object) {
                key = (prefix ? `${prefix}[${key}]` : key);
            }

            if (typeof value === 'object') {
                return this.serializeQuery(value, key);
            }
            else {
                return `${key}=${encodeURIComponent(value)}`;
            }
        });

        return [].concat.apply([], query).join('&');
    }

    /**
     * Serialize nested object
     *
     * @param params
     * @param prefix
     * @returns {string}
     */
    static serializeNestedQuery(params, prefix = null) {
        /**
         * Serialize a single key-value pair.
         * @param {string} key - The key to serialize.
         * @param {*} value - The value to serialize.
         * @param {string|null} prefix - The prefix for nested parameters.
         * @returns {string} - The serialized key-value pair.
         */
        function serializePair(key, value, prefix) {
            const prefixedKey = prefix ? `${prefix}[${key}]` : key;

            // Recursively serialize nested objects and arrays
            if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
                return Helper.serializeQuery(value, prefixedKey);
            }
            else if (value !== null && Array.isArray(value)) {
                return value.map((item, index) => Helper.serializeQuery(item, `${prefixedKey}[${index}]`)).join('&');
            }
            else {
                return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
            }
        }

        // Map each key-value pair to its serialized form and join them with '&'
        const query = Object.keys(params).map(key => serializePair(key, params[key], prefix));

        return query.join('&');
    }

    /**
     * Create an asynchronous request
     *
     * @param url
     * @param params
     * @param dataType
     * @param method
     * @returns {Promise<Response>}
     */
    static request(url, params = {}, dataType, method = 'GET') {
        let options = {
            method
        };

        if (method.toUpperCase() === 'GET') {
            // Serialize request data
            url += '?' + new URLSearchParams(params).toString();
        }
        else {
            if (params instanceof FormData) {
                // Pass form data
                options.body = params;

                options.headers = {
                    'X-Requested-With': 'XMLHttpRequest',
                };
            }
            else {
                // Stringify and pass data
                options.body = JSON.stringify(params);

                // CSRF protection
                options.headers = {
                    'X-Requested-With'  : 'XMLHttpRequest',
                    'Content-Type'      : 'application/json',
                    'Accept'            : 'application/json',
                    'X-CSRF-Token'      : document.querySelector('meta[name="csrf-token"]').content
                };
            }
        }

        return fetch(url, options).then(async (response) => {
            if (response.status === 200) {
                return await (dataType === 'json')
                    ? response.json()
                    : response.text();
            }

            throw {
                'statusCode'    : response.status,
                'statusText'    : response.statusText,
                'response'      : await response.json()
            };
        });
    }

    static get(url, params, dataType = 'json') {
        return this.request(url, params, dataType, 'GET');
    }

    static post(url, params, dataType = 'json') {
        return this.request(url, params, dataType, 'POST');
    }

    static put(url, params, dataType = 'json') {
        return this.request(url, params, dataType, 'PUT');
    }

    static destroy(url, params, dataType = 'json') {
        return this.request(url, params, dataType, 'DELETE');
    }
}
